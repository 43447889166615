<template>
  <div class="docs-block-inner">
    <div class="wrapper">
      <div class="docs-block">
        <h2 class="text-subheader docs-block">{{ title }}</h2>
        <a
          v-for="(file, index) in files"
          :key="`block_docks_${id}_${index}`"
          class="docs-block__file flex flex-ai-center gap-10"
          :download="file.file_name"
          target="_blank"
          :href="`${url}${file.file}`"
        >
          <div class="docs-block__file-name flex-11a">
            {{ file.title || file.file_name }}
          </div>
          <div class="docs-block__file-info flex-00a">
            {{ file.file_extension }}
          </div>
          <div class="docs-block__file-info flex-00a">({{ file.file_size_formatted }})</div>
          <div class="docs-block__file-download flex-00a">
            <NuxtIcon name="IconDownload" />
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { BlockDocsFile } from '@/types/pages';
import { useRuntimeConfig } from '#imports';
import './styles.scss';

interface Props {
  files: BlockDocsFile[];
  id: number;
  title?: string;
}

const url = useRuntimeConfig().public.siteUrl;

defineProps<Props>();
</script>
